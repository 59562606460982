









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import userHts from "@/constants/userHts";
import makeSelectOptions from "@common/util/makeSelectOptions";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(item) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý giá bán sản phẩm",
          urlEnabled: true,
          search: {},
          filters: {
            filters: {
              productId: {
                type: "XAutocomplete",
                attrs: {
                  label: "SP",
                  "item-value": "_id",
                  "item-text": "sku",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: item._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              marketId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("markets", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: item._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              startTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày bắt đầu (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              endTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc (>=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày kết thúc (<=)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              company: {
                type: "boolean",
                attrs: {
                  label: "Toàn công ty",
                },
                rules: {
                  equal_to: {},
                },
              },
              userHt: {
                type: "select",
                attrs: {
                  label: "Hình thức",
                  items: [{ value: "", text: "Trống" }].concat(makeSelectOptions(userHts)),
                },
                rules: {
                  equal_to: {},
                },
              },
              teamIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Teams",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              departmentIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              userIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Users",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
            },
          },
          displayFields: {
            product: {
              text: "Sản phẩm",
              sortable: true,
              options: {
                subProp: "product.sku",
                sortBy: "productId",
              },
            },
            market: {
              text: "Thị trường",
              sortable: true,
              options: {
                subProp: "market.name",
                sortBy: "marketId",
              },
            },
            startTime: {
              text: "Ngày bắt đầu",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            endTime: {
              text: "Ngày kết thúc",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            description: {
              text: "Mô tả",
            },
            value: {
              text: "Giá",
              sortable: true,
            },
            userHt: {
              text: "Hình thức",
              options: {
                label(value) {
                  return userHts[value];
                },
              },
            },
            company: {
              text: "Toàn công ty",
              options: {
                boolean: true,
              },
            },
            teams: {
              text: "Team",
              options: {
                labels(items) {
                  return items && items.map((item) => item.name);
                },
              },
            },
            departments: {
              text: "Phòng",
              options: {
                labels(items) {
                  return items && items.map((item) => item.name);
                },
              },
            },
            users: {
              text: "User",
              options: {
                labels(items) {
                  return items && items.map((item) => item.name);
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "productPrices",
                                  "import",
                                  {
                                    countryId: item._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("productPrices", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker({ product }) {
                        return `Sản phẩm ${product.sku}`;
                      },
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: item._id,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("productPrices", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.countryId = dataTable.context().$route.params.countryId;
              return await coreApiClient.call("productPrices", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "productPrices",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("productPrices", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              productId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  required: true,
                  "item-value": "_id",
                  "item-text": "sku",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: item._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              marketId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thị trường",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("markets", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: item._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              startTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày bắt đầu",
                  required: true,
                },
              },
              endTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày kết thúc",
                  required: true,
                },
              },
              value: {
                type: "number",
                attrs: {
                  label: "Giá",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              company: {
                type: "boolean",
                attrs: {
                  label: "Toàn công ty",
                },
              },
              userHt: {
                type: "select",
                attrs: {
                  label: "Hình thức",
                  items: makeSelectOptions(userHts),
                },
              },
              teamIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Teams",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("teams", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              departmentIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Phòng",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("departments", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
              userIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "User",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("users", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: ["productId", "marketId", "startTime", "endTime", "value", "description", "company", "userHt", "teamIds", "departmentIds", "userIds"],
              },
            },
            editForm: userManager.checkRole(["kt"]) && {
              content: {
                fieldNames: ["productId", "marketId", "startTime", "endTime", "value", "description", "company", "userHt", "teamIds", "departmentIds", "userIds"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField(item) {
                return item.product.sku;
              },
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(this.item);
  },
});
